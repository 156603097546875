import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Section from './components/section/Section';
import Child6 from './components/child-section/child-6';

const UploadPage = () => {
  useEffect(() => {
    document.title = "Upload Page";
  }, []);

  return (
    <div className="ladi-wraper">
      <ToastContainer
        position="bottom-left"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme="light"
        style={{ zIndex: 90000080 }}
      />
      <Section height="675px">
        <Child6 />
      </Section>
    </div>
  );
};

export default UploadPage;
