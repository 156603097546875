import React, { useState, useEffect } from 'react';
import './styles/rotate-screen.css';
// ...existing code...

const RotateOverlay = () => {
  const [isLandscape, setIsLandscape] = useState(window.innerWidth > window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setIsLandscape(window.innerWidth > window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      {!isLandscape && (
        <div className="rotate-screen-overlay">
          <img src="/rotate.gif" alt="Rotate your phone" />
          <p>Vui lòng xoay điện thoại của bạn sang chế độ nằm ngang để tiếp tục.</p>
        </div>
      )}
    </div>
  );
};

export default RotateOverlay;
