import React, { useState, useEffect } from 'react';

const SubChild1 = () => {
  // State to store the current background image for BOX2
  const [backgroundImageBox2, setBackgroundImageBox2] =
    useState('/images/co-dau.webp');
  // const [isFirstImageBox2, setIsFirstImageBox2] = useState(true); // State to track which image is currently displayed for BOX2

  // State to store the current background image for BOX3
  const [backgroundImageBox3, setBackgroundImageBox3] =
    useState('/images/chu-re.webp');
  // const [isFirstImageBox3, setIsFirstImageBox3] = useState(true); // State to track which image is currently displayed for BOX3

  // Function to toggle background image for BOX2
  const handleBox2Click = () => {
    // if (isFirstImageBox2) {
    //   setBackgroundImageBox2('/images/codau-2.webp'); // Change to new image for BOX2
    // } else {
    //   setBackgroundImageBox2('/images/co-dau.webp'); // Revert to original image for BOX2
    // }
    // setIsFirstImageBox2(!isFirstImageBox2); // Toggle the state for BOX2
  };

  // Function to toggle background image for BOX3
  const handleBox3Click = () => {
    // if (isFirstImageBox3) {
    //   setBackgroundImageBox3('/images/chure-2.webp'); // Change to new image for BOX3
    // } else {
    //   setBackgroundImageBox3('/images/chu-re.webp'); // Revert to original image for BOX3
    // }
    // setIsFirstImageBox3(!isFirstImageBox3); // Toggle the state for BOX3
  };

  // Effect to change the background after 10 seconds for BOX2
  // useEffect(() => {
  //   const timerBox2 = setTimeout(() => {
  //     handleBox2Click(); // Automatically change background for BOX2
  //   }, 120000);

  //   return () => clearTimeout(timerBox2); // Cleanup timeout on unmount or change
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isFirstImageBox2]);

  // Effect to change the background after 10 seconds for BOX3
  // useEffect(() => {
  //   const timerBox3 = setTimeout(() => {
  //     handleBox3Click(); // Automatically change background for BOX3
  //   }, 120000); // 10 seconds

  //   return () => clearTimeout(timerBox3); // Cleanup timeout on unmount or change
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isFirstImageBox3]);

  return (
    <>
      <div className="ladi-section-background"></div>
      <div className="ladi-container">
        <div id="IMAGE39" className="ladi-element">
          <div className="ladi-image">
            <div className="ladi-image-background"></div>
          </div>
        </div>
        <div id="IMAGE38" className="ladi-element">
          <div className="ladi-image">
            <div className="ladi-image-background"></div>
          </div>
        </div>
        <div id="IMAGE37" className="ladi-element">
          <div className="ladi-image">
            <div className="ladi-image-background"></div>
          </div>
        </div>
        <div id="IMAGE4" className="ladi-element">
          <div className="ladi-image">
            <div className="ladi-image-background"></div>
          </div>
        </div>
        <div id="HEADLINE7" className="ladi-element ladi-animation">
          <p className="ladi-headline">
            Love is a journey that grows deeper with every step we take together
          </p>
        </div>
        <div id="BOX2" className="ladi-element" onClick={handleBox2Click}>
          <div
            className="ladi-box ladi-transition"
            style={{ backgroundImage: `url(${backgroundImageBox2})` }}
          ></div>
        </div>
        <div id="HEADLINE8" className="ladi-element ladi-animation">
          <p className="ladi-headline">CÔ DÂU</p>
        </div>
        <div id="HEADLINE9" className="ladi-element ladi-animation">
          <p className="ladi-headline ladi-transition">Nguyễn Phương Anh</p>
        </div>
        <div id="BOX3" className="ladi-element" onClick={handleBox3Click}>
          <div
            className="ladi-box ladi-transition"
            style={{ backgroundImage: `url(${backgroundImageBox3})` }}
          ></div>
        </div>
        <div id="HEADLINE12" className="ladi-element ladi-animation">
          <p className="ladi-headline">CHÚ RỂ</p>
        </div>
        <div id="HEADLINE13" className="ladi-element ladi-animation">
          <p className="ladi-headline ladi-transition">Đàm Việt Đức</p>
        </div>
      </div>
    </>
  );
};

export default SubChild1;
