import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import axios from 'axios';

const Child6 = () => {
  const [file, setFile] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const validFiles = selectedFiles.filter(
      (file) =>
        file.type.startsWith('image/') || file.type.startsWith('video/'),
    );
    if (validFiles.length !== selectedFiles.length) {
      toast.error('Chỉ chấp nhận những định dạng hình ảnh và video.');
    }
    setFile(validFiles);
  };

  const handleUpload = async () => {
    if (file.length === 0) {
      toast.error('Hãy lựa chọn tấm hình xinh đẹp để gửi đến chúng mình nhé !');
      return;
    }

    const invalidFiles = file.filter(
      (fileItem) =>
        !fileItem.type.startsWith('image/') &&
        !fileItem.type.startsWith('video/'),
    );
    if (invalidFiles.length > 0) {
      toast.error('Chỉ chấp nhận những định dạng hình ảnh và video.');
      return;
    }

    toast.info(
      'File đang được tải lên sẽ mất một chút thời gian bạn hãy đợi nhé !',
    );

    setLoading(true);

    for (let i = 0; i < file.length; i++) {
      const formData = new FormData();
      formData.append('file', file[i]);

      try {
        await axios.post(
          'api/upload', // Updated to point to backend service
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          },
        );
        toast.success(`File ${i + 1} trên ${file.length} đã tải lên thành công!`);
      } catch (error) {
        console.error(error);
        toast.error(`Lỗi khi tải lên file ${i + 1} trên ${file.length}.`);
      }
    }

    setLoading(false);
  };

  return (
    <>
      <div id="IMAGE90" className="ladi-element">
        <div className="ladi-image">
          <div className="ladi-image-background"></div>
        </div>
      </div>
      <div id="GROUP90" className="ladi-element">
        <div className="ladi-group">
          <div id="IMAGE32" className="ladi-element">
            <div className="ladi-image">
              <div className="ladi-image-background"></div>
            </div>
          </div>
          <div id="IMAGE29" className="ladi-element">
            <div className="ladi-image">
              <div className="ladi-image-background"></div>
            </div>
          </div>
          <div id="IMAGE33" className="ladi-element">
            <div className="ladi-image">
              <div className="ladi-image-background"></div>
            </div>
          </div>
          <div id="IMAGE28" className="ladi-element">
            <div className="ladi-image">
              <div className="ladi-image-background"></div>
            </div>
          </div>
          <div id="IMAGE31" className="ladi-element">
            <div className="ladi-image">
              <div className="ladi-image-background"></div>
            </div>
          </div>
          <div id="IMAGE34" className="ladi-element">
            <div className="ladi-image">
              <div className="ladi-image-background"></div>
            </div>
          </div>
          <div id="IMAGE35" className="ladi-element">
            <div className="ladi-image">
              <div className="ladi-image-background"></div>
            </div>
          </div>
        </div>
      </div>
      <div id="IMAGE97" className="ladi-element">
        <div className="ladi-image">
          <div className="ladi-image-background"></div>
        </div>
      </div>
      <div id="IMAGE91" className="ladi-element">
        <div className="ladi-image">
          <div className="ladi-image-background"></div>
        </div>
      </div>
      <div id="HEADLINE90" className="ladi-element ladi-animation">
        <p className="ladi-headline">
          Share love with us
          <br />
        </p>
      </div>
      <div id="HEADLINE98" className="ladi-element ladi-animation">
        <p className="ladi-headline">
          Hãy gửi cho chúng mình những bức ảnh và video xinh đẹp mà bạn đã chụp
          được tại đám cưới nhé.
          <br />
        </p>
      </div>
      <div id="FORM_ITEM_UPLOAD" className="ladi-element">
        <div className="ladi-form-item-container">
          <div className="ladi-form-item">
            <input
              autoComplete="off"
              tabIndex="1"
              name="name"
              required
              type="file"
              multiple
              onChange={handleFileChange}
            />
          </div>
        </div>
      </div>
      <div
        data-action="true"
        id="GROUP99"
        className="ladi-element ladi-animation"
        onClick={handleUpload}
        disabled={loading}
      >
        <div className="ladi-group">
          <div id="BOX16" className="ladi-element">
            <div className="ladi-box ladi-transition"></div>
          </div>
          <div data-action="true" id="HEADLINE61" className="ladi-element">
            <p className="ladi-headline ladi-transition">
              {loading ? (
                <>
                  <AiOutlineLoading3Quarters className="loading-icon" />
                  ĐANG TẢI LÊN...
                </>
              ) : (
                'TẢI LÊN'
              )}
              <br />
            </p>
          </div>
        </div>
      </div>
      <div id="HEADLINE99" className="ladi-element ladi-animation">
        <p className="ladi-headline">
          Cảm ơn bạn rất nhiều vì đã gửi những bức ảnh và video xinh đẹp đến đám
          cưới của chúng mình.
          <br />
        </p>
      </div>
    </>
  );
};

export default Child6;
