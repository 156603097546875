import React from 'react';
import HaiPhongPage from '../location/HaiPhong';
import ThanhHoaPage from '../location/ThanhHoa';
import { useParams } from 'react-router-dom';

const Child2 = () => {
  const { location } = useParams();
  if (location === 'haiphong') {
    return <HaiPhongPage />;
  } else if (location === 'thanhhoa') {
    return <ThanhHoaPage />;
  }
  return null;
};

export default Child2;
