import axios from "axios";

const BASE_URL = "/api"; // Thay đổi URL nếu cần

export const getInvitations = async (fileId) => {
  return await axios.get(`${BASE_URL}/invites/${fileId}`);
};

export const getInvitationById = async (fileId, id) => {
  return await axios.get(`${BASE_URL}/invite/${fileId}/${id}`);
};

export const addInvitation = async (fileId, name) => {
  return await axios.post(`${BASE_URL}/invite/${fileId}`, { name });
};

export const updateInvitation = async (fileId, id, name) => {
  return await axios.put(`${BASE_URL}/invite/${fileId}/${id}`, { name });
};

export const deleteInvitation = async (fileId, id) => {
  return await axios.delete(`${BASE_URL}/invite/${fileId}/${id}`);
};
