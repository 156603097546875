import React, { useEffect, useState } from 'react';
import {
  getInvitations,
  addInvitation,
  updateInvitation,
  deleteInvitation,
} from './services/InvitationService';
import './InvitationsPage.css';
import { ToastContainer, toast } from 'react-toastify';

const InvitationsPage = () => {
  const [invitations, setInvitations] = useState([]);
  const [newName, setNewName] = useState('');
  const [editId, setEditId] = useState(null);
  const [editName, setEditName] = useState('');
  const [isPhuHuynh, setIsPhuHuynh] = useState(false);
  
  const fileId = 'duc-phanh-wedding'

  const fetchInvitations = async () => {
    try {
      const response = await getInvitations(fileId); // Truyền fileId vào hàm
      setInvitations(response.data);
    } catch (error) {
      toast.error('Failed to fetch invitations:', error);
    }
  };

  useEffect(() => {
    fetchInvitations();
  }, []);
  
  const handleAddInvitation = async () => {
    if (!newName) {
      toast.error('Please provide a name.');
      return;
    }

    try {
      await addInvitation(fileId, newName); // Truyền fileId vào hàm
      setNewName('');
      fetchInvitations();
    } catch (error) {
      toast.error('Failed to add invitation:', error);
    }
  };

  const handleUpdateInvitation = async () => {
    if (!editName || !editId) {
      toast.error('Please provide valid data for updating.');
      return;
    }

    try {
      await updateInvitation(fileId, editId, editName); // Truyền fileId vào hàm
      setEditId(null);
      setEditName('');
      fetchInvitations();
    } catch (error) {
      toast.error('Failed to update invitation:', error);
    }
  };

  const handleDeleteInvitation = async (id) => {
    try {
      await deleteInvitation(fileId, id); // Truyền fileId vào hàm
      fetchInvitations();
    } catch (error) {
      toast.error('Failed to delete invitation:', error);
    }
  };

  const handleCopyLink = (id, location) => {
    let link = `https://loveourtime.site/${location}/${id}`;
    if (isPhuHuynh) {
      link += '?phuhuynh';
    }
    navigator.clipboard
      .writeText(link)
      .then(() => toast.success('Link copied: ' + link))
      .catch((err) => toast.error('Failed to copy link: ', err));
  };
  
  return (
    <div className="container">
      <ToastContainer
        position="bottom-left"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme="light"
        style={{ zIndex: 90000080 }}
      />
      <h1 className="header-title">Invitations</h1>

      {/* Add new invitation */}
      <div>
        <input
          className="input-field"
          type="text"
          placeholder="Enter name"
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
        />
        <button className="button" onClick={handleAddInvitation}>
          Add
        </button>
      </div>

      {/* Edit invitation */}
      {editId && (
        <div style={{ marginTop: '20px' }}>
          <input
            className="input-field"
            type="text"
            placeholder="Edit name"
            value={editName}
            onChange={(e) => setEditName(e.target.value)}
          />
          <button className="button" onClick={handleUpdateInvitation}>
            Update
          </button>
          <button className="button cancel" onClick={() => setEditId(null)}>
            Cancel
          </button>
        </div>
      )}

      {/* Checkbox for Phu Huynh */}
      <div style={{ marginTop: '20px' }}>
        <label>
          <input
            type="checkbox"
            checked={isPhuHuynh}
            onChange={(e) => setIsPhuHuynh(e.target.checked)}
          />
          Phụ huynh
        </label>
      </div>

      {/* Invitations list */}
      <ul>
        {Object.entries(invitations).map(([id, name]) => (
          <li key={id}>
            <span>
              <strong>{name}</strong> <small>(ID: {id})</small>
            </span>
            <div className="edit-buttons">
              <button
                className="button"
                onClick={() => setEditId(id) || setEditName(name)}
              >
                Edit
              </button>
              <button
                className="button delete"
                onClick={() => handleDeleteInvitation(id)}
              >
                Delete
              </button>
              <button className="button" onClick={() => handleCopyLink(id, 'haiphong')}>
                Copy Link (Hai Phong)
              </button>
              <button className="button" onClick={() => handleCopyLink(id, 'thanhhoa')}>
                Copy Link (Thanh Hoa)
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default InvitationsPage;
