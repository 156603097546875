import React from 'react';

const Section = ({ height, children }) => {
  return (
    <section className="ladi-section" style={{ height: height }}>
      <div className="ladi-section-background"></div>
      <div className="ladi-container">{children}</div>
    </section>
  );
};

export default Section;
