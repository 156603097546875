import React from 'react';

const Child5 = () => {
  return (
    <div>
      <div id="IMAGE16" className="ladi-element">
        <div className="ladi-image">
          <div className="ladi-image-background"></div>
        </div>
      </div>
      <div id="HEADLINE67" className="ladi-element ladi-animation-hidden">
        <p className="ladi-headline">Thank you!</p>
      </div>
      <div id="HEADLINE72" className="ladi-element">
        <p className="ladi-headline ladi-transition">
          PHƯƠNG ANH & VIỆT ĐỨC WEDDING INVITATION
          <br />
        </p>
      </div>
    </div>
  );
};

export default Child5;
