import React, { useState, useEffect } from 'react';
import './SplashScreen.css'; // Import the CSS file
import Section from './components/section/Section';

const SplashScreen = ({ isLoading, content, onHidden }) => {
  const [isHidden, setIsHidden] = useState(false);
  const [isRemoved, setIsRemoved] = useState(false);
  const [ceremonyMessage, setCeremonyMessage] = useState(
    'Đến dự buổi tiệc cưới chung vui cùng gia đình chúng mình',
  );
  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        setIsHidden(true);
        setTimeout(() => {
          setIsRemoved(true);
          if (onHidden) {
            onHidden();
          }
        }, 1000); // Delay to allow fade-out animation to complete
      }, 4000);
    }
  }, [isLoading, onHidden]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('phuhuynh')) {
      setCeremonyMessage(
        'Đến dự buổi tiệc cưới chung vui cùng gia đình chúng tôi',
      );
    }
  }, []);

  if (isRemoved) return null;

  return (
    <div className="ladi-wraper">
      <Section height="675px">
        <div className={`splash-screen ${isHidden ? 'fade-out' : ''}`}>
          <div className="splash-screen-content">
            <div id="HEADLINE190" className="ladi-element">
              <div className="ladi-image">
                <div className="ladi-image-background"></div>
              </div>
            </div>
            <div id="GROUP90" className="ladi-element">
              <div className="ladi-group">
                <div id="IMAGE32" className="ladi-element">
                  <div className="ladi-image">
                    <div className="ladi-image-background"></div>
                  </div>
                </div>
                <div id="IMAGE29" className="ladi-element">
                  <div className="ladi-image">
                    <div className="ladi-image-background"></div>
                  </div>
                </div>
                <div id="IMAGE33" className="ladi-element">
                  <div className="ladi-image">
                    <div className="ladi-image-background"></div>
                  </div>
                </div>
                <div id="IMAGE28" className="ladi-element">
                  <div className="ladi-image">
                    <div className="ladi-image-background"></div>
                  </div>
                </div>
                <div id="IMAGE31" className="ladi-element">
                  <div className="ladi-image">
                    <div className="ladi-image-background"></div>
                  </div>
                </div>
                <div id="IMAGE34" className="ladi-element">
                  <div className="ladi-image">
                    <div className="ladi-image-background"></div>
                  </div>
                </div>
                <div id="IMAGE35" className="ladi-element">
                  <div className="ladi-image">
                    <div className="ladi-image-background"></div>
                  </div>
                </div>
              </div>
            </div>
            <div id="IMAGE98" className="ladi-element">
              <div className="ladi-image">
                <div className="ladi-image-background"></div>
              </div>
            </div>
            <div id="IMAGE91" className="ladi-element">
              <div className="ladi-image">
                <div className="ladi-image-background"></div>
              </div>
            </div>
            <div id="HEADLINE190" className="ladi-element ladi-animation">
              <p className="ladi-headline">
                Thư Mời
                <br />
              </p>
            </div>
            <div id="HEADLINE98" className="ladi-element ladi-animation">
              <p className="ladi-headline">
                Trân trọng kính mời
                <br />
              </p>
            </div>
            <div id="HEADLINE101" className="ladi-element ladi-animation">
              <p className="ladi-headline">
                <div dangerouslySetInnerHTML={{ __html: content }}></div>
                <br />
              </p>
            </div>
            <div id="HEADLINE102" className="ladi-element ladi-animation">
              <p className="ladi-headline">
                {ceremonyMessage}
                <br />
              </p>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
};

export default SplashScreen;
