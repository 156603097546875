import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const Child1 = () => {
  const { location } = useParams();

  // State to store the current background image
  const [backgroundImage, setBackgroundImage] = useState(
    '/images/anh-phong.webp',
  );
  // const [isFirstImage, setIsFirstImage] = useState(true);

  // Function to toggle between images
  const handleBoxClick = () => {
    // if (isFirstImage) {
    //   setBackgroundImage('/images/anh-supbox.webp');
    // } else {
    //   setBackgroundImage('/images/anh-phong.webp');
    // }
    // setIsFirstImage(!isFirstImage);
  };

  // Effect to change the background after 10 seconds for BOX2
  // useEffect(() => {
  //   const timerBox2 = setTimeout(() => {
  //     handleBoxClick(); // Automatically change background for BOX2
  //   }, 120000); // 10 seconds

  //   return () => clearTimeout(timerBox2); // Cleanup timeout on unmount or change
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isFirstImage]);

  return (
    <div>
      <div id="IMAGE36" className="ladi-element">
        <div className="ladi-image">
          <div className="ladi-image-background"></div>
        </div>
      </div>
      <div
        id="BOX1"
        className="ladi-element"
        onClick={handleBoxClick} // Thêm sự kiện click
      >
        <div
          className="ladi-box ladi-transition"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        ></div>
      </div>
      <div id="HEADLINE3" className="ladi-element ladi-animation">
        <p className="ladi-headline ladi-transition">
          Phương Anh
          <br />
        </p>
      </div>
      <div id="GROUP17" className="ladi-element ladi-animation">
        <div className="ladi-group">
          <div id="HEADLINE4" className="ladi-element">
            <p className="ladi-headline ladi-transition">
              &amp;
              <br />
            </p>
          </div>
          <div id="HEADLINE5" className="ladi-element">
            <p className="ladi-headline ladi-transition">
              Việt Đức
              <br />
            </p>
          </div>
        </div>
      </div>
      <div id="IMAGE1" className="ladi-element ladi-animation">
        <div className="ladi-image">
          <div className="ladi-image-background"></div>
        </div>
      </div>
      <div id="IMAGE3" className="ladi-element ladi-animation">
        <div className="ladi-image">
          <div className="ladi-image-background"></div>
        </div>
      </div>
      <div id="HEADLINE6" className="ladi-element ladi-animation">
        <p className="ladi-headline">
          {location === 'thanhhoa' ? '07.02.2025' : ''}
          {location === 'haiphong' ? '13.02.2025' : ''}
          <br />
        </p>
      </div>
    </div>
  );
};

export default Child1;
