import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PopupTK from '../popup/popup-tk';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { badWord } from '../../services/bad-words';

const Child4 = () => {
  const { location } = useParams();
  const [confirmMessage, setConfirmMessage] = useState(
    'Hãy xác nhận sự có mặt của bạn để chúng mình',
  );
  const [thankYouMessage, setThankYouMessage] = useState(
    'Cảm ơn bạn rất nhiều vì đã gửi những lời chúc mừng tốt đẹp nhất đến đám cưới của chúng mình.',
  );

  const [
    isPopupWeddingCelebrationVisible,
    setIsPopupWeddingCelebrationVisible,
  ] = useState(false);

  const commonPopup = () => {
    if (!isPopupWeddingCelebrationVisible) {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'relative';
      document.body.style.height = '100%';
      document.body.style.touchAction = 'none';
      document.body.style.msTouchAction = 'none';
    } else {
      document.body.style.overflow = 'auto';
      document.body.style.position = '';
      document.body.style.height = '';
      document.body.style.touchAction = '';
      document.body.style.msTouchAction = '';
    }
  };
  const [timeLeft, setTimeLeft] = useState({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00',
  });

  const [formData, setFormData] = useState({
    name: '',
    message: '',
    attendance: '',
    quantity: '',
  });

  const [errors, setErrors] = useState({});

  // Add loading state
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let endTime = new Date().getTime();
    if (location === 'haiphong') {
      endTime = new Date('2025-02-13T11:00:00+07:00').getTime();
    } else if (location === 'thanhhoa') {
      endTime = new Date('2025-02-07T11:00:00+07:00').getTime();
    }

    const timer = setInterval(() => {
      const now = new Date().getTime();
      const distance = endTime - now;

      if (distance > 0) {
        setTimeLeft({
          days: Math.floor(distance / (1000 * 60 * 60 * 24))
            .toString()
            .padStart(2, '0'),
          hours: Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
          )
            .toString()
            .padStart(2, '0'),
          minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
            .toString()
            .padStart(2, '0'),
          seconds: Math.floor((distance % (1000 * 60)) / 1000)
            .toString()
            .padStart(2, '0'),
        });
      }
    }, 1000);

    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = 'Vui lòng nhập tên của bạn';
    }

    if (formData.attendance === 'Tôi nhất định sẽ tham gia') {
      if (!formData.quantity) {
        newErrors.quantity = 'Vui lòng chọn số lượng người tham gia';
      }
    }

    if (
      formData.name.trim() &&
      !formData.message.trim() &&
      !formData.attendance
    ) {
      newErrors.message =
        'Vui lòng nhập lời nhắn hoặc chỉ cần xác nhận trạng thái tham dự';
    }

    if (formData.name.trim() && !formData.attendance) {
      newErrors.attendance = 'Vui lòng chọn trạng thái tham dự';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const hasCurseWords = badWord.some((word) => {
      return (
        formData.name
          .trim()
          .toLowerCase()
          .includes(word.toLowerCase().trim()) ||
        formData.message
          .trim()
          .toLowerCase()
          .includes(word.toLowerCase().trim())
      );
    });

    if (hasCurseWords) {
      toast.error(
        'Lời nhắn hoặc tên chứa từ nhạy cảm, vui lòng kiểm tra lại!',
        {
          position: 'bottom-left',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        },
      );
      return;
    }

    setIsLoading(true); // Start loading
    const url = new URL('https://docs.google.com/forms/d/e/1FAIpQLScoaSqGXEshlWewXDuRGmHQyJeOEs3m96-JQk4LODH9YpeXiA/formResponse');
    const formDataUrl = new URLSearchParams();
    formDataUrl.append('entry.1431059352', formData.name); // Tên người tham gia
    formDataUrl.append('entry.601943252', formData.message); // Tin nhắn
    formDataUrl.append('entry.1670247928', formData.attendance); // Tham gia (có hoặc không)
    formDataUrl.append('entry.1193315944', formData.quantity || '1'); // Số lượng (mặc định là 1 nếu không có)
    
    Promise.all([
      fetch(url, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formDataUrl,
      }),
    ])
      .then(() => {
        toast('🤵💕👰 Cảm ơn bạn đã gửi thông tin !');
        setFormData({ name: '', message: '', attendance: '', quantity: '' });
      })
      .catch((error) => {
        console.error('Error:', error);
        toast.error('Có lỗi xảy ra, vui lòng thử lại sau!', {
          position: 'bottom-left',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .finally(() => {
        setIsLoading(false); // Stop loading
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('phuhuynh')) {
      setConfirmMessage('Vui lòng xác nhận sự có mặt để gia đình chúng tôi');
      setThankYouMessage('Cảm ơn vì đã gửi lời chúc tới gia đình chúng tôi.');
    }
  }, []);

  return (
    <>
      {isPopupWeddingCelebrationVisible && (
        <PopupTK
          setIsOpen={() => {
            setIsPopupWeddingCelebrationVisible(false);
            commonPopup();
          }}
        />
      )}
      <div id="IMAGE48" className="ladi-element">
        <div className="ladi-image">
          <div className="ladi-image-background"></div>
        </div>
      </div>
      <div id="IMAGE47" className="ladi-element">
        <div className="ladi-image">
          <div className="ladi-image-background"></div>
        </div>
      </div>
      <div id="IMAGE14" className="ladi-element">
        <div className="ladi-image">
          <div className="ladi-image-background"></div>
        </div>
      </div>
      <div id="HEADLINE59" className="ladi-element ladi-animation-hidden">
        <p className="ladi-headline">
          Xác nhận tham dự
          <br />
        </p>
      </div>
      <div id="HEADLINE60" className="ladi-element ladi-animation-hidden">
        <p className="ladi-headline">
          {confirmMessage}
          <br />
          chuẩn bị đón tiếp một cách chu đáo nhất. Trân trọng!
          <br />
        </p>
      </div>
      <div id="FORM2" className="ladi-element ladi-animation-hidden">
        <form className="ladi-form" onSubmit={handleSubmit}>
          <div id="FORM_ITEM2" className="ladi-element">
            <div className="ladi-form-item-container">
              <div className="ladi-form-item-background"></div>
              <div className="ladi-form-item">
                <input
                  autoComplete="off"
                  tabIndex="1"
                  name="name"
                  required
                  className={`ladi-form-control ${errors.name ? 'error' : ''}`}
                  type="text"
                  placeholder="Tên của bạn"
                  value={formData.name}
                  onChange={handleInputChange}
                />
                {errors.name && (
                  <div className="error-message">{errors.name}</div>
                )}
              </div>
            </div>
          </div>
          <div id="FORM_ITEM3" className="ladi-element">
            <div className="ladi-form-item-container">
              <div className="ladi-form-item-background"></div>
              <div className="ladi-form-item">
                <textarea
                  autoComplete="off"
                  tabIndex="4"
                  name="message"
                  className={`ladi-form-control ${errors.message ? 'error' : ''}`}
                  placeholder="Lời nhắn của bạn"
                  value={formData.message}
                  onChange={handleInputChange}
                ></textarea>
                {errors.message && (
                  <div className="error-message">{errors.message}</div>
                )}
              </div>
            </div>
          </div>
          <div id="FORM_ITEM4" className="ladi-element">
            <div className="ladi-form-item-container">
              <div className="ladi-form-item-background"></div>
              <div
                className={`ladi-form-item ladi-form-checkbox ladi-form-checkbox-vertical ${errors.attendance ? 'error' : ''}`}
              >
                <div className="ladi-form-checkbox-box-item">
                  {[
                    'Tôi nhất định sẽ tham gia',
                    'Thật tiếc tôi bận mất rồi',
                    'Tôi chỉ muốn gửi lời nhắn',
                  ].map((option, index) => (
                    <div className="ladi-form-checkbox-item" key={option}>
                      <input
                        id={`attendance-${index}`}
                        tabIndex="3"
                        name="attendance"
                        type="radio"
                        value={option}
                        checked={formData.attendance === option}
                        onChange={handleInputChange}
                      />
                      <label for={`attendance-${index}`}>{option}</label>
                    </div>
                  ))}
                </div>
                {errors.attendance && (
                  <div className="error-message">{errors.attendance}</div>
                )}
              </div>
            </div>
          </div>
          <div
            id="FORM_ITEM5"
            className={`ladi-element ${formData.attendance !== 'Tôi nhất định sẽ tham gia' ? 'hidden' : ''}`}
          >
            <div className="ladi-form-item-container">
              <div
                className={`ladi-form-item ladi-form-checkbox ladi-form-checkbox-vertical ${errors.quantity ? 'error' : ''}`}
              >
                <label className="form-title">Số lượng người tham gia:</label>
                <div className="ladi-form-checkbox-box-item">
                  {['1', '2', '3'].map((option, index) => (
                    <div className="ladi-form-checkbox-item" key={option}>
                      <input
                        id={`quantity-${index}`}
                        tabIndex="5"
                        name="quantity"
                        type="radio"
                        value={option}
                        checked={formData.quantity === option}
                        onChange={handleInputChange}
                      />
                      <label for={`quantity-${index}`}>{option}</label>
                    </div>
                  ))}
                </div>
                {errors.quantity && (
                  <div className="error-message">{errors.quantity}</div>
                )}
              </div>
            </div>
          </div>

          <div id="BUTTON2" className="ladi-element ladi-animation-hidden">
            <div className="ladi-button ladi-transition">
              <div className="ladi-button-background"></div>
              <button
                id="BUTTON_TEXT2"
                type="submit"
                className="ladi-element ladi-button-headline"
                disabled={isLoading}
              >
                <p className="ladi-headline ladi-transition">
                  {isLoading ? (
                    <AiOutlineLoading3Quarters className="loading-spinner" />
                  ) : (
                    'GỬI LỜI NHẮN VÀ XÁC NHẬN'
                  )}
                </p>
              </button>
            </div>
          </div>
        </form>
      </div>
      <div
        onClick={() => {
          setIsPopupWeddingCelebrationVisible(
            !isPopupWeddingCelebrationVisible,
          );
          commonPopup();
        }}
        data-action="true"
        id="GROUP14"
        className="ladi-element ladi-animation-hidden"
      >
        <div className="ladi-group">
          <div id="BOX16" className="ladi-element">
            <div className="ladi-box ladi-transition"></div>
          </div>
          <div data-action="true" id="HEADLINE61" className="ladi-element">
            <p className="ladi-headline ladi-transition">
              MỪNG CƯỚI
              <br />
            </p>
          </div>
        </div>
      </div>
      <div id="HEADLINE62" className="ladi-element ladi-animation-hidden">
        <p className="ladi-headline">
          {thankYouMessage}
          <br />
        </p>
      </div>
      <div id="GROUP15" className="ladi-element ladi-animation-hidden">
        <div className="ladi-group">
          <div id="BOX17" className="ladi-element">
            <div className="ladi-box ladi-transition"></div>
          </div>
          <div id="HEADLINE63" className="ladi-element">
            <p className="ladi-headline">Time</p>
          </div>
          <div id="COUNTDOWN1" className="ladi-element">
            <div className="ladi-countdown">
              <div id="COUNTDOWN_ITEM1" className="ladi-element">
                <div className="ladi-countdown-background"></div>
                <div className="ladi-countdown-text">
                  <span>{timeLeft.days}</span>
                </div>
              </div>
              <div id="COUNTDOWN_ITEM2" className="ladi-element">
                <div className="ladi-countdown-background"></div>
                <div className="ladi-countdown-text">
                  <span>{timeLeft.hours}</span>
                </div>
              </div>
              <div id="COUNTDOWN_ITEM3" className="ladi-element">
                <div className="ladi-countdown-background"></div>
                <div className="ladi-countdown-text">
                  <span>{timeLeft.minutes}</span>
                </div>
              </div>
              <div id="COUNTDOWN_ITEM4" className="ladi-element">
                <div className="ladi-countdown-background"></div>
                <div className="ladi-countdown-text">
                  <span>{timeLeft.seconds}</span>
                </div>
              </div>
            </div>
          </div>
          <div id="HEADLINE64" className="ladi-element">
            <h3 className="ladi-headline ladi-transition">
              :<br />
            </h3>
          </div>
          <div id="HEADLINE65" className="ladi-element">
            <h3 className="ladi-headline ladi-transition">
              :<br />
            </h3>
          </div>
          <div id="HEADLINE66" className="ladi-element">
            <h3 className="ladi-headline ladi-transition">
              :<br />
            </h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default Child4;
