import './App.css';
import '../src/styles/animatev4.css';
import '../src/styles/style_animation.css';
import { useState, useRef, useEffect } from 'react';
import Section from './components/section/Section';
import Child1 from './components/child-section/child-1';
import Child2 from './components/child-section/child-2';
import Child3 from './components/child-section/child-3';
import Child4 from './components/child-section/child-4';
import ChildWish from './components/child-section/child-wish';
import SubChild1 from './components/child-section/subchild-1';
import { FaVolumeXmark, FaVolumeHigh } from 'react-icons/fa6';
import Child5 from './components/child-section/child-5';
import LazyLoad from 'react-lazyload';
import { ToastContainer } from 'react-toastify';
import SplashScreen from './SplashScreen';
import { useParams } from 'react-router-dom';

function HomePage() {
  const { id } = useParams();
  const [content, setContent] = useState('');
  const [isSplashHidden, setIsSplashHidden] = useState(false);

  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [playlist, setPlaylist] = useState([
    '/music/BestPart-HERDanielCaesar.mp3',
    '/music/OldLove-Yujiputridahlia.mp3',
    '/music/Photograph-EdSheeran.mp3',
  ]);
  const [currentSongIndex, setCurrentSongIndex] = useState(0);
  const shufflePlaylist = () => {
    const shuffled = [...playlist].sort(() => Math.random() - 0.5);
    setPlaylist(shuffled);
    setCurrentSongIndex(0); // Reset chỉ số bài hát hiện tại
  };
  const playNextSong = () => {
    setCurrentSongIndex((prevIndex) => {
      const nextIndex = (prevIndex + 1) % playlist.length;
      return nextIndex; // Trả về chỉ số bài hát tiếp theo
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll('.ladi-animation-hidden');
      elements.forEach((element) => {
        const rect = element.getBoundingClientRect();
        if (rect.top < window.innerHeight && rect.bottom > 0) {
          element.classList.remove('ladi-animation-hidden');
          element.classList.add('ladi-animation');
        }
      });
    };
    window.addEventListener('scroll', handleScroll);
    shufflePlaylist();
    const tryPlayAudio = () => {
      const audioElem = audioRef.current;
      if (audioElem) {
        audioElem
          .play()
          .then(() => {
            setIsPlaying(true);
            clearInterval(retryInterval); // Stop retrying once it plays successfully
          })
          .catch(() => {
            // If play fails, it will retry after 1 seconds
          });
      }
    };

    const retryInterval = setInterval(tryPlayAudio, 1000);
    tryPlayAudio(); // Initial attempt to play
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearInterval(retryInterval); // Clean up the interval on component unmount
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleAudio = () => {
    const audioElem = audioRef.current;
    if (audioElem) {
      if (isPlaying) {
        audioElem.pause();
      } else {
        if (audioElem) {
          audioElem.play().catch(() => {
            // If play fails, it will retry after 1 seconds
          });
        }
      }
      setIsPlaying(!isPlaying);
    }
  };

  useEffect(() => {
    const audioElem = audioRef.current;
    if (audioElem) {
      audioElem.play().catch(() => {
        // If play fails, it will retry after 1 seconds
      });
    }
  }, [currentSongIndex]); // Phát bài hát mới khi currentSongIndex thay đổi

  useEffect(() => {
    const fetchName = async () => {
      try {
        const response = await fetch(`/api/invite/duc-phanh-wedding/${id}`);
        if (response.ok) {
          const data = await response.json();
          setContent(data.name);
        } else {
          setContent('error');
        }
      } catch (error) {}
    };

    if (id) {
      fetchName();
    }
  }, [id]); // useEffect chạy lại khi id thay đổi

  return (
    <>
      {id && content !== 'error' && (
        <SplashScreen
          isLoading={content === ''}
          content={content}
          onHidden={() => setIsSplashHidden(true)}
        />
      )}
      {isSplashHidden | !id && (
        <div className="ladi-wraper">
          <div onClick={toggleAudio} className="audio-toggle-button">
            <div className="playerIcon">
              {isPlaying ? (
                <span id="playerVolumeOn">
                  <FaVolumeHigh />
                </span>
              ) : (
                <span id="playerVolumeOff">
                  <FaVolumeXmark />
                </span>
              )}
            </div>
          </div>
          <audio
            ref={audioRef}
            src={playlist[currentSongIndex]} // Sử dụng bài hát hiện tại
            type="audio/mpeg"
            loop={false} // Đặt loop thành false để không phát lại bài hát
            onEnded={playNextSong} // Chuyển sang bài hát tiếp theo khi kết thúc
          ></audio>
          <ToastContainer
            position="bottom-left"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            draggable
            theme="light"
            style={{ zIndex: 90000080 }}
          />
          <Section height="663px">
            <LazyLoad offset={200} once>
              <Child1 />
            </LazyLoad>
          </Section>
          <Section height="1500px">
            <LazyLoad offset={200} once>
              <SubChild1 />
            </LazyLoad>
          </Section>
          <Section height="1640px">
            <LazyLoad offset={200} once>
              <Child2 />
            </LazyLoad>
          </Section>
          <Section height="1546px">
            <LazyLoad offset={200} once>
              <Child3 />
            </LazyLoad>
          </Section>
          {/* <Section height="375px">
            <LazyLoad offset={200} once>
              <ChildWish />
            </LazyLoad>
          </Section> */}
          <Section height="922px">
            <LazyLoad offset={200} once>
              <Child4 />
            </LazyLoad>
          </Section>
          <Section height="260px">
            <LazyLoad offset={200} once>
              <Child5 />
            </LazyLoad>
          </Section>
        </div>
      )}
    </>
  );
}

export default HomePage;
