import React, { useState, useEffect } from 'react';

const ThanhHoaPage = () => {
    const [ceremonyMessage, setCeremonyMessage] = useState(
      'Lễ Vu Quy của chúng mình',
    );
  
    useEffect(() => {
      const params = new URLSearchParams(window.location.search);
      if (params.has('phuhuynh')) {
        setCeremonyMessage(
          'Lễ Vu Quy của hai con chúng tôi',
        );
      }
    }, []);
  return (
    <div>
      <div id="IMAGE43" className="ladi-element">
        <div className="ladi-image">
          <div className="ladi-image-background"></div>
        </div>
      </div>
      <div id="IMAGE42" className="ladi-element">
        <div className="ladi-image">
          <div className="ladi-image-background"></div>
        </div>
      </div>
      <div id="IMAGE41" className="ladi-element">
        <div className="ladi-image">
          <div className="ladi-image-background"></div>
        </div>
      </div>
      <div id="IMAGE40" className="ladi-element">
        <div className="ladi-image">
          <div className="ladi-image-background"></div>
        </div>
      </div>
      <div id="IMAGE8" className="ladi-element">
        <div className="ladi-image">
          <div className="ladi-image-background"></div>
        </div>
      </div>
      <div id="IMAGE9" className="ladi-element">
        <div className="ladi-image">
          <div className="ladi-image-background"></div>
        </div>
      </div>
      <div id="LINE2" className="ladi-element">
        <div className="ladi-line">
          <div className="ladi-line-container"></div>
        </div>
      </div>
      <div id="HEADLINE20" className="ladi-element ladi-animation-hidden">
        <p className="ladi-headline">
          LỄ
          <br />
          VU
          <br />
          QUY
          <br />
        </p>
      </div>
      <div id="LINE3" className="ladi-element">
        <div className="ladi-line">
          <div className="ladi-line-container"></div>
        </div>
      </div>
      <div id="GROUP2" className="ladi-element ladi-animation">
        <div className="ladi-group">
          <div id="HEADLINE21" className="ladi-element">
            <p className="ladi-headline">Nhà Gái</p>
          </div>
          <div id="HEADLINE23" className="ladi-element">
            <p className="ladi-headline">Ông Nguyễn Thế Sợi</p>
          </div>
          <div id="HEADLINE24" className="ladi-element">
            <p className="ladi-headline">Bà Nguyễn Thị Cúc</p>
          </div>
          <div id="HEADLINE25" className="ladi-element">
            <p className="ladi-headline">Thành phố Thanh Hóa</p>
          </div>
        </div>
      </div>
      <div id="GROUP3" className="ladi-element ladi-animation">
        <div className="ladi-group">
          <div id="HEADLINE26" className="ladi-element">
            <p className="ladi-headline">Nhà Trai</p>
          </div>
          <div id="HEADLINE27" className="ladi-element">
            <p className="ladi-headline">Ông Đàm Văn Hằng</p>
          </div>
          <div id="HEADLINE28" className="ladi-element">
            <p className="ladi-headline">Bà Lê Thị Thúy</p>
          </div>
          <div id="HEADLINE29" className="ladi-element">
            <p className="ladi-headline">Thành phố Hải Phòng</p>
          </div>
        </div>
      </div>
      <div id="HEADLINE30" className="ladi-element ladi-animation-hidden">
        <p className="ladi-headline">Trân trọng báo tin</p>
      </div>
      <div id="HEADLINE31" className="ladi-element ladi-animation-hidden">
        <p className="ladi-headline">{ceremonyMessage}</p>
      </div>
      <div id="HEADLINE32" className="ladi-element ladi-animation-hidden">
        <p className="ladi-headline">NGUYỄN PHƯƠNG ANH</p>
      </div>
      <div id="HEADLINE33" className="ladi-element ladi-animation-hidden">
        <p className="ladi-headline">ĐÀM VIỆT ĐỨC</p>
      </div>
      <div id="HEADLINE34" className="ladi-element ladi-animation-hidden">
        <p className="ladi-headline">
          Together with our family!
          <br />
        </p>
      </div>
      <div id="GROUP18" className="ladi-element ladi-animation-hidden">
        <div className="ladi-group">
          <div id="HEADLINE37" className="ladi-element">
            <p className="ladi-headline">07</p>
          </div>
          <div id="HEADLINE38" className="ladi-element">
            <p className="ladi-headline">2025</p>
          </div>
          <div id="GROUP5" className="ladi-element">
            <div className="ladi-group">
              <div id="LINE4" className="ladi-element">
                <div className="ladi-line">
                  <div className="ladi-line-container"></div>
                </div>
              </div>
              <div id="LINE5" className="ladi-element">
                <div className="ladi-line">
                  <div className="ladi-line-container"></div>
                </div>
              </div>
            </div>
          </div>
          <div id="GROUP6" className="ladi-element">
            <div className="ladi-group">
              <div id="LINE6" className="ladi-element">
                <div className="ladi-line">
                  <div className="ladi-line-container"></div>
                </div>
              </div>
              <div id="LINE7" className="ladi-element">
                <div className="ladi-line">
                  <div className="ladi-line-container"></div>
                </div>
              </div>
            </div>
          </div>
          <div id="HEADLINE39" className="ladi-element">
            <p className="ladi-headline">THỨ SÁU</p>
          </div>
          <div id="GROUP7" className="ladi-element">
            <div className="ladi-group">
              <div id="HEADLINE40" className="ladi-element">
                <p className="ladi-headline">11:00</p>
              </div>
              <div id="HEADLINE42" className="ladi-element">
                <p className="ladi-headline">AM</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="HEADLINE35" className="ladi-element ladi-animation-hidden">
        <p className="ladi-headline">THÁNG 02</p>
      </div>
      <div id="HEADLINE43" className="ladi-element ladi-animation-hidden">
        <p className="ladi-headline">(Tức ngày 10 tháng 01 năm Ất Tỵ)</p>
      </div>
      <div id="GROUP8" className="ladi-element ladi-animation-hidden">
        <div className="ladi-group">
          <div id="HEADLINE44" className="ladi-element">
            <p className="ladi-headline">
              TIỆC THÂN MẬT ĐƯỢC TỔ CHỨC
              <br />
              VÀO LÚC&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
              <br />
            </p>
          </div>
          <div id="HEADLINE45" className="ladi-element">
            <p className="ladi-headline">11:00</p>
          </div>
        </div>
      </div>
      <div id="GROUP19" className="ladi-element ladi-animation-hidden">
        <div className="ladi-group">
          <div id="IMAGE10" className="ladi-element">
            <div className="ladi-image">
              <div className="ladi-image-background"></div>
            </div>
          </div>
          <div id="HEADLINE47" className="ladi-element">
            <p className="ladi-headline">10:30</p>
          </div>
          <div id="HEADLINE50" className="ladi-element">
            <p className="ladi-headline">Đón Khách</p>
          </div>
        </div>
      </div>
      <div id="GROUP21" className="ladi-element ladi-animation-hidden">
        <div className="ladi-group">
          <div id="IMAGE11" className="ladi-element">
            <div className="ladi-image">
              <div className="ladi-image-background"></div>
            </div>
          </div>
          <div id="HEADLINE48" className="ladi-element">
            <p className="ladi-headline">11:00</p>
          </div>
          <div id="HEADLINE51" className="ladi-element">
            <p className="ladi-headline">Nâng ly</p>
          </div>
        </div>
      </div>
      <div id="GROUP20" className="ladi-element ladi-animation-hidden">
        <div className="ladi-group">
          <div id="IMAGE12" className="ladi-element">
            <div className="ladi-image">
              <div className="ladi-image-background"></div>
            </div>
          </div>
          <div id="HEADLINE49" className="ladi-element">
            <p className="ladi-headline">11:15</p>
          </div>
          <div id="HEADLINE52" className="ladi-element">
            <p className="ladi-headline">Khai tiệc</p>
          </div>
        </div>
      </div>
      <div id="HEADLINE53" className="ladi-element ladi-animation-hidden">
        <p className="ladi-headline">
          TRUNG TÂM TỔ CHỨC SỰ KIỆN WHITE PALACE Hội Trường LonDon - Sảnh Châu
          Âu
        </p>
      </div>
      <div id="HEADLINE55" className="ladi-element ladi-animation-hidden">
        <p className="ladi-headline">
          Địa chỉ: Đại lộ Võ Nguyên Giáp - Phường Đông Vệ - Thanh Hóa
        </p>
      </div>
      <a
        href="https://maps.app.goo.gl/j62j2sMsJP55TJU78"
        target="_blank"
        rel="noreferrer"
        id="GROUP10"
        className="ladi-element ladi-animation-hidden"
        data-replace-href="https://maps.app.goo.gl/j62j2sMsJP55TJU78"
      >
        <div className="ladi-group">
          <div id="IMAGE13" className="ladi-element">
            <div className="ladi-image">
              <div className="ladi-image-background"></div>
            </div>
          </div>
          <div id="HEADLINE56" className="ladi-element">
            <p className="ladi-headline">CHỈ ĐƯỜNG</p>
          </div>
        </div>
      </a>
      <div id="MAP" className="ladi-element ladi-animation-hidden">
        <div className="ladi-map frame-html-box">
          {/* AIzaSyDaOulQACiJzBfqumbsqg_-vKha8fCnL-s */}
          <iframe
            title="Google Maps Location"
            width="600"
            height="450"
            style={{ border: 0 }}
            loading="lazy"
            allowFullScreen
            src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJsa5PuaRXNjERHQwJtMRqi6g&key=AIzaSyDaOulQACiJzBfqumbsqg_-vKha8fCnL-s"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ThanhHoaPage;
