export const badWord = [
  'xxx',
  'nyc',
  'em nho anh',
  'người yêu cũ',
  'nho em',
  'nho anh',
  'nhớ em',
  'nhớ anh',
  'yêu em',
  'yêu anh',
  'yeu e',
  'yeu a',
];
