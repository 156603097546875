import React, { useEffect, useState } from 'react';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';

const Child3 = () => {
  const [imageDetails, setImageDetails] = useState({
    '/images/album-1.webp': { width: 0, height: 0 },
    '/images/album-3.webp': { width: 0, height: 0 },
    '/images/album-8.webp': { width: 0, height: 0 },
    '/images/album-4.webp': { width: 0, height: 0 },
    '/images/album-5.webp': { width: 0, height: 0 },
    '/images/album-6.webp': { width: 0, height: 0 },
    '/images/album-7.webp': { width: 0, height: 0 },
    '/images/album-2.webp': { width: 0, height: 0 },
    '/images/album-9.webp': { width: 0, height: 0 },
    '/images/album-10.webp': { width: 0, height: 0 },
    '/images/album-11.webp': { width: 0, height: 0 },
  });
  useEffect(() => {
    const lightbox = new PhotoSwipeLightbox({
      // may select multiple "galleries"
      gallery: '#gallery--zoom-transition',

      // Elements within gallery (slides)
      children: 'a',
      showHideAnimationType: 'zoom',
      // setup PhotoSwipe Core dynamic import
      pswpModule: () => import('photoswipe'),
    });
    lightbox.init();
  }, []);

  const handleImageLoad = (src) => {
    const img = new Image();
    img.src = src;

    img.onload = () => {
      setImageDetails((prevDetails) => ({
        ...prevDetails,
        [src]: { width: img.width, height: img.height },
      }));
    };
  };

  // Trigger image load for each image
  useEffect(() => {
    Object.keys(imageDetails).forEach((src) => handleImageLoad(src));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="gallery--zoom-transition">
      {/* Keep original IMAGE elements */}
      <div id="IMAGE46" className="ladi-element">
        <div className="ladi-image">
          <div className="ladi-image-background"></div>
        </div>
      </div>
      <div id="IMAGE45" className="ladi-element">
        <div className="ladi-image">
          <div className="ladi-image-background"></div>
        </div>
      </div>
      <div id="IMAGE44" className="ladi-element">
        <div className="ladi-image">
          <div className="ladi-image-background"></div>
        </div>
      </div>

      {/* Define each image box manually */}
      <div id="BOX5" className="ladi-element ladi-animation-hidden">
        <a
          href="/images/album-1.webp"
          target="_blank"
          className="ladi-box ladi-transition"
          title=""
          data-pswp-width={imageDetails['/images/album-1.webp'].width}
          data-pswp-height={imageDetails['/images/album-1.webp'].height}
        ></a>
      </div>
      <div id="BOX6" className="ladi-element ladi-animation-hidden">
        <a
          href="/images/album-3.webp"
          target="_blank"
          className="ladi-box ladi-transition"
          title=""
          data-pswp-width={imageDetails['/images/album-3.webp'].width}
          data-pswp-height={imageDetails['/images/album-3.webp'].height}
        ></a>
      </div>
      <div id="BOX7" className="ladi-element ladi-animation-hidden">
        <a
          href="/images/album-8.webp"
          target="_blank"
          className="ladi-box ladi-transition"
          title=""
          data-pswp-width={imageDetails['/images/album-8.webp'].width}
          data-pswp-height={imageDetails['/images/album-8.webp'].height}
        ></a>
      </div>
      <div id="BOX8" className="ladi-element ladi-animation-hidden">
        <a
          href="/images/album-4.webp"
          target="_blank"
          className="ladi-box ladi-transition"
          title=""
          data-pswp-width={imageDetails['/images/album-4.webp'].width}
          data-pswp-height={imageDetails['/images/album-4.webp'].height}
        ></a>
      </div>
      <div id="BOX9" className="ladi-element ladi-animation-hidden">
        <a
          href="/images/album-5.webp"
          target="_blank"
          className="ladi-box ladi-transition"
          title=""
          data-pswp-width={imageDetails['/images/album-5.webp'].width}
          data-pswp-height={imageDetails['/images/album-5.webp'].height}
        ></a>
      </div>
      <div id="BOX10" className="ladi-element ladi-animation-hidden">
        <a
          href="/images/album-6.webp"
          target="_blank"
          className="ladi-box ladi-transition"
          title=""
          data-pswp-width={imageDetails['/images/album-6.webp'].width}
          data-pswp-height={imageDetails['/images/album-6.webp'].height}
        ></a>
      </div>
      <div id="BOX11" className="ladi-element ladi-animation-hidden">
        <a
          href="/images/album-7.webp"
          target="_blank"
          className="ladi-box ladi-transition"
          title=""
          data-pswp-width={imageDetails['/images/album-7.webp'].width}
          data-pswp-height={imageDetails['/images/album-7.webp'].height}
        ></a>
      </div>
      <div id="BOX12" className="ladi-element ladi-animation-hidden">
        <a
          href="/images/album-2.webp"
          target="_blank"
          className="ladi-box ladi-transition"
          title=""
          data-pswp-width={imageDetails['/images/album-2.webp'].width}
          data-pswp-height={imageDetails['/images/album-2.webp'].height}
        ></a>
      </div>
      <div id="BOX13" className="ladi-element ladi-animation-hidden">
        <a
          href="/images/album-9.webp"
          target="_blank"
          className="ladi-box ladi-transition"
          title=""
          data-pswp-width={imageDetails['/images/album-9.webp'].width}
          data-pswp-height={imageDetails['/images/album-9.webp'].height}
        ></a>
      </div>
      <div id="BOX14" className="ladi-element ladi-animation-hidden">
        <a
          href="/images/album-10.webp"
          target="_blank"
          className="ladi-box ladi-transition"
          title=""
          data-pswp-width={imageDetails['/images/album-10.webp'].width}
          data-pswp-height={imageDetails['/images/album-10.webp'].height}
        ></a>
      </div>
      <div id="BOX15" className="ladi-element ladi-animation-hidden">
        <a
          href="/images/album-11.webp"
          target="_blank"
          className="ladi-box ladi-transition"
          title=""
          data-pswp-width={imageDetails['/images/album-11.webp'].width}
          data-pswp-height={imageDetails['/images/album-11.webp'].height}
        ></a>
      </div>

      <div id="GROUP22" className="ladi-element ladi-animation-hidden">
        <div className="ladi-group">
          <div id="HEADLINE57" className="ladi-element">
            <p className="ladi-headline">
              Album
              <br />
            </p>
          </div>
          <div id="HEADLINE58" className="ladi-element">
            <p className="ladi-headline">(Click Để Xem)</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Child3;
