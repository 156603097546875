import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './HomePage';
import UploadPage from './UploadPage';
import WaitingRoom from './WaitingRoom';
import RotateOverlay from './RotateOverlay';
import InvitationsPage from './InvitationsPage';

const App = () => {
  return (
    <Router>
      <div>
        {/* Route Configuration */}
        <Routes>
          <Route path="/" element={<WaitingRoom />} />
          <Route path="/:location/:id?" element={<HomePage />} />
          <Route path="/video" element={<RotateOverlay />} />
          <Route path="/upload" element={<UploadPage />} />
          <Route path="/edit" element={<InvitationsPage />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
