import React from 'react';
import copy from 'copy-to-clipboard';
import { FaRegCopy } from 'react-icons/fa';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PopupTK = ({ setIsOpen }) => {
  const notifyCopyMan = () => toast('🤵 Đã Sao Chép Số Tài Khoản Chú Rể!');
  const notifyCopyWoman = () => toast('👰 Đã Sao Chép Số Tài Khoản Cô Dâu !');
  return (
    <>
      <div id="SECTION_POPUP" className="ladi-section">
        <div className="ladi-section-background"></div>
        <div className="ladi-container">
          <div
            id="POPUP1"
            className="ladi-element"
            data-popup-backdrop="true"
            style={{ display: 'block !important;' }}
          >
            <div className="ladi-popup">
              <div className="ladi-popup-background"></div>
              <div id="GROUP25" className="ladi-element">
                <div className="ladi-group">
                  <div id="IMAGE50" className="ladi-element">
                    <div className="ladi-image">
                      <div className="ladi-image-background"></div>
                    </div>
                  </div>
                  <div id="IMAGE51" className="ladi-element">
                    <div className="ladi-image">
                      <div className="ladi-image-background"></div>
                    </div>
                  </div>
                  <div id="IMAGE55" className="ladi-element">
                    <div className="ladi-image">
                      <div className="ladi-image-background"></div>
                    </div>
                  </div>
                  {/* <div id="BOX18" className="ladi-element">
                    <div className="ladi-box ladi-transition"></div>
                  </div> */}
                  <div id="IMAGE56" className="ladi-element">
                    <div className="ladi-image">
                      <div className="ladi-image-background"></div>
                    </div>
                  </div>
                  <div id="IMAGE57" className="ladi-element ladi-animation">
                    <div className="ladi-image">
                      <div className="ladi-image-background"></div>
                    </div>
                  </div>
                  <div id="GROUP24" className="ladi-element ladi-animation">
                    <div className="ladi-group">
                      <div
                        id="HEADLINE68"
                        className="ladi-element ladi-animation"
                      >
                        <p className="ladi-headline ladi-transition">
                          cô dâu
                          <br />
                        </p>
                      </div>
                      <div
                        id="HEADLINE69"
                        className="ladi-element ladi-animation"
                      >
                        <p className="ladi-headline ladi-transition">
                          Nguyễn Phương Anh
                        </p>
                      </div>
                      <div
                        id="HEADLINE70"
                        className="ladi-element ladi-animation"
                      >
                        <p className="ladi-headline ladi-transition">
                          TechcomBank
                          <br />
                        </p>
                      </div>
                      <div
                        id="HEADLINE71"
                        className="ladi-element ladi-animation"
                        onClick={() => {
                          copy('19037906314012');
                          notifyCopyWoman();
                        }}
                      >
                        <p className="ladi-headline ladi-transition">
                          19037906314012 <FaRegCopy />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div id="IMAGE59" className="ladi-element ladi-animation">
                    <div className="ladi-image">
                      <div className="ladi-image-background"></div>
                    </div>
                  </div>
                  <div id="GROUP27" className="ladi-element ladi-animation">
                    <div className="ladi-group">
                      <div
                        id="HEADLINE68"
                        className="ladi-element ladi-animation"
                      >
                        <p className="ladi-headline ladi-transition">
                          chú rể
                          <br />
                        </p>
                      </div>
                      <div
                        id="HEADLINE69"
                        className="ladi-element ladi-animation"
                      >
                        <p className="ladi-headline ladi-transition">
                          Đàm Việt Đức
                        </p>
                      </div>
                      <div
                        id="HEADLINE70"
                        className="ladi-element ladi-animation"
                      >
                        <p className="ladi-headline ladi-transition">
                          TechComBank
                          <br />
                        </p>
                      </div>
                      <div
                        id="HEADLINE71"
                        className="ladi-element ladi-animation"
                        onClick={() => {
                          copy('19036260010013');
                          notifyCopyMan();
                        }}
                      >
                        <p className="ladi-headline ladi-transition">
                          19036260010013 <FaRegCopy />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              onClick={() => {
                setIsOpen();
              }}
              className="popup-close"
            ></div>
          </div>
          <div
            onClick={() => {
              setIsOpen();
            }}
            id="backdrop-popup"
            className="backdrop-popup"
          ></div>
        </div>
      </div>
    </>
  );
};

export default PopupTK;
