import React from 'react';
import 'react-toastify/dist/ReactToastify.css';

const Child7 = () => {
  const navigateToThanhHoa = () => {
    window.location.href = '/thanhhoa';
  };

  const navigateToHaiPhong = () => {
    window.location.href = '/haiphong';
  };

  return (
    <>
      <div id="HEADLINE190" className="ladi-element">
        <div className="ladi-image">
          <div className="ladi-image-background"></div>
        </div>
      </div>
      <div id="IMAGE98" className="ladi-element">
        <div className="ladi-image">
          <div className="ladi-image-background"></div>
        </div>
      </div>
      <div id="IMAGE91" className="ladi-element">
        <div className="ladi-image">
          <div className="ladi-image-background"></div>
        </div>
      </div>
      <div id="HEADLINE190" className="ladi-element ladi-animation">
        <p className="ladi-headline">
          Địa Điểm Tổ Chức
          <br />
        </p>
      </div>
      <div id="HEADLINE98" className="ladi-element ladi-animation">
        <p className="ladi-headline">
          Hãy chọn địa điểm tham dự 🤵💕👰
          <br />
        </p>
      </div>
      <div
        data-action="true"
        id="GROUP199"
        className="ladi-element ladi-animation"
        onClick={navigateToThanhHoa}
      >
        <div className="ladi-group">
          <div id="BOX116" className="ladi-element">
            <div className="ladi-box ladi-transition"></div>
          </div>
          <div data-action="true" id="HEADLINE161" className="ladi-element">
            <p className="ladi-headline ladi-transition">
              Thanh Hóa
              <br />
            </p>
          </div>
        </div>
      </div>
      <div
        data-action="true"
        id="GROUP200"
        className="ladi-element ladi-animation"
        onClick={navigateToHaiPhong}
      >
        <div className="ladi-group">
          <div id="BOX117" className="ladi-element">
            <div className="ladi-box ladi-transition"></div>
          </div>
          <div data-action="true" id="HEADLINE162" className="ladi-element">
            <p className="ladi-headline ladi-transition">
              Hải Phòng
              <br />
            </p>
          </div>
        </div>
      </div>
      <div id="HEADLINE100" className="ladi-element ladi-animation">
        <p className="ladi-headline">
          Cảm ơn bạn rất nhiều vì đã xem thiệp cưới của chúng mình.
          <br />
        </p>
      </div>
    </>
  );
};

export default Child7;
