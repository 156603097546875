import React, { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Section from './components/section/Section';
import Child7 from './components/child-section/child-7';

const WaitingRoom = () => {
  useEffect(() => {
    document.title = "Home Page";
  }, []);

  return (
    <div className="ladi-wraper">
      <Section height="675px">
        <Child7 />
      </Section>
    </div>
  );
};

export default WaitingRoom;
